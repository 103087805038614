import { useTranslations } from 'next-intl';

import NavigationDivider from '../root-layout/Items/NavigationDivider';
import NavigationItem from '../root-layout/Items/NavigationItem';
import NavigationTitle from '../root-layout/Items/NavigationTitle';

type SidebarProps = {
  activeSection: string;
  onActiveSectionChange: (section: string) => void;
};

const Sidebar = ({ activeSection, onActiveSectionChange }: SidebarProps) => {
  const t = useTranslations();
  return (
    <div className="flex flex-col flex-shrink-0 border-r border-gray-100 bg-gray-50 px-4">
      <div className="py-6 px-2 text-sm font-medium text-gray-800">
        {t('SettingsModal.settings')}
      </div>
      <nav className="flex-1">
        {/* <div> */}
        <NavigationTitle>{t('SettingsModal.personal')}</NavigationTitle>
        <NavigationItem
          onClick={() => onActiveSectionChange('Account')}
          icon={{
            source: 'hero',
            type: activeSection === 'account' ? 'solid' : 'line',
            name: 'UserIcon',
          }}
          active={activeSection === 'account'}
          locked={true}
        >
          {t('SettingsModal.account')}
        </NavigationItem>
        <NavigationItem
          onClick={() => onActiveSectionChange('preferences')}
          icon={{
            source: 'hero',
            type: activeSection === 'preferences' ? 'solid' : 'line',
            name: 'AdjustmentsVerticalIcon',
          }}
          active={activeSection === 'preferences'}
        >
          {t('SettingsModal.preferences')}
        </NavigationItem>
        <NavigationDivider />
        <NavigationTitle>{t('SettingsModal.company')}</NavigationTitle>
        <NavigationItem
          onClick={() => onActiveSectionChange('general')}
          icon={{
            source: 'hero',
            type: activeSection === 'general' ? 'solid' : 'line',
            name: 'Cog8ToothIcon',
          }}
          active={activeSection === 'general'}
          locked={true}
        >
          {t('SettingsModal.general')}
        </NavigationItem>
        <NavigationItem
          onClick={() => onActiveSectionChange('teammates')}
          icon={{
            source: 'hero',
            type: activeSection === 'teammates' ? 'solid' : 'line',
            name: 'UsersIcon',
          }}
          active={activeSection === 'teammates'}
        >
          {t('SettingsModal.teammates')}
        </NavigationItem>
        <NavigationItem
          onClick={() => onActiveSectionChange('reductionTargets')}
          icon={{
            source: 'hero',
            type: activeSection === 'reductionTargets' ? 'solid' : 'line',
            name: 'RocketLaunchIcon',
          }}
          active={activeSection === 'reductionTargets'}
        >
          {t('SettingsModal.reductionTargets')}
        </NavigationItem>
        <NavigationItem
          onClick={() => onActiveSectionChange('modeling')}
          icon={{
            source: 'hero',
            type: activeSection === 'modeling' ? 'solid' : 'line',
            name: 'BeakerIcon',
          }}
          active={activeSection === 'modeling'}
        >
          {t('SettingsModal.modeling')}
        </NavigationItem>
        {/* </div> */}
      </nav>
    </div>
  );
};

export default Sidebar;
