import BetaTag from 'app/components/BetaTag';
import type { IconType, LocalIconKey } from 'app/components/Icon';
import { usePathname } from 'app/navigation';
import { useTranslations } from 'next-intl';

import { AccountFeatureFlag, useFeatureFlags } from 'app/hooks/useFeatureFlag';
import NavigationDivider from './Items/NavigationDivider';
import NavigationItem from './Items/NavigationItem';
import NavigationTitle from './Items/NavigationTitle';

const MainNavigationPanel = () => {
  const t = useTranslations();
  const pathname = usePathname();
  const rootRoute = pathname.split('/')[1];

  const featureFlags = useFeatureFlags();

  const getIcon = (rootName: string, name: LocalIconKey): IconType => {
    return {
      source: 'local',
      name,
      type: rootRoute === rootName ? 'solid' : 'line',
    };
  };

  return (
    <div>
      <NavigationItem
        href="/home"
        icon={getIcon(rootRoute, 'home')}
        active={rootRoute === 'home'}
      >
        {t('LeftMenu.home')}
      </NavigationItem>

      <NavigationDivider />
      <NavigationTitle>{t('LeftMenu.collect')}</NavigationTitle>
      <NavigationItem
        href="/materials"
        icon={getIcon('materials', 'microchip')}
        active={rootRoute === 'materials'}
      >
        {t('LeftMenu.materials')}
      </NavigationItem>
      <NavigationItem
        href="/products"
        icon={getIcon('products', 'styler')}
        active={rootRoute === 'products'}
      >
        {t('LeftMenu.products')}
      </NavigationItem>
      <NavigationItem
        href="/data-augmentation-rules"
        icon={getIcon('data-augmentation-rules', 'ruler')}
        active={rootRoute === 'data-augmentation-rules'}
      >
        {t('LeftMenu.dataAugmentationRules')}
      </NavigationItem>
      {featureFlags.includes(AccountFeatureFlag.CarbonAccounting) && (
        <>
          <NavigationItem
            href="/corporate"
            icon={getIcon('corporate', 'briefcase')}
            active={rootRoute === 'corporate'}
          >
            {t('LeftMenu.corporate')}
          </NavigationItem>
          <NavigationItem
            href="/operations/facilities"
            icon={getIcon('facilities', 'shop')}
            active={pathname === '/operations/facilities'}
          >
            {t('LeftMenu.facilities')}
          </NavigationItem>
        </>
      )}

      <NavigationDivider />
      <NavigationTitle>{t('LeftMenu.measure')}</NavigationTitle>
      <NavigationItem
        href="/trends"
        icon={getIcon('trends', 'line-up')}
        active={rootRoute === 'trends'}
      >
        {t('LeftMenu.trends')}
      </NavigationItem>
      <NavigationItem
        href="/explorer"
        icon={getIcon('explorer', 'perspective')}
        active={rootRoute === 'explorer'}
      >
        <div className="flex flex-row items-center justify-start flex-nowrap gap-2">
          {t('LeftMenu.explorer')}
        </div>
      </NavigationItem>

      <NavigationDivider />
      <NavigationTitle>{t('LeftMenu.reduce')}</NavigationTitle>
      <NavigationItem
        href="/product-modeling"
        icon={getIcon('product-modeling', 't-shirt')}
        active={rootRoute === 'product-modeling'}
      >
        {t('LeftMenu.productModeling')}
      </NavigationItem>
      <NavigationItem
        href="/company-modeling"
        icon={getIcon('company-modeling', 'trolley')}
        active={rootRoute === 'company-modeling'}
      >
        {t('LeftMenu.companyModeling')}
        <BetaTag />
      </NavigationItem>

      <NavigationDivider />
      <NavigationTitle>{t('LeftMenu.report')}</NavigationTitle>
      <NavigationItem
        href="/reports"
        icon={getIcon('reports', 'reports')}
        active={rootRoute === 'reports'}
      >
        {t('LeftMenu.reports')}
      </NavigationItem>
    </div>
  );
};

export default MainNavigationPanel;
