import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

interface CheckboxProps {
  checked?: boolean;
  label?: string;
  disabled?: boolean;
  className?: string;
  readOnly?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Checkbox({
  checked,
  label,
  disabled,
  className,
  readOnly,
  onChange,
}: CheckboxProps) {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    // Set checkbox to indeterminate if checked is undefined
    // This state is only accessible via the DOM API, no HTML attribute
    if (typeof checked === 'undefined') {
      ref.current.indeterminate = true;
    } else {
      ref.current.indeterminate = false;
    }
  }, [checked]);

  const htmlId = useMemo(() => uniqueId('input_check_'), []);

  return (
    <div className="flex flex-row gap-2 items-center justify-start">
      <input
        type="checkbox"
        id={htmlId}
        ref={ref}
        checked={checked}
        disabled={disabled}
        className={classNames(
          className,
          'text-carbon-700 rounded-md border-[1.5px]',
          checked ? 'border-carbon-700' : 'border-gray-300',
          disabled ? 'opacity-50 cursor-auto' : 'cursor-pointer',
        )}
        onChange={onChange}
        readOnly={readOnly}
      />
      {label && (
        <label htmlFor={htmlId} className="text-sm text-carbon-800">
          {label}
        </label>
      )}
    </div>
  );
}
