import { Dropdown } from 'app/components/Dropdown';
import MissingDataError from 'app/components/Error/Custom/MissingDataError';
import InlineError from 'app/components/Error/InlineError';
import Loader from 'app/components/Loader';
import { useFetchWithAuth } from 'app/context/SWRContext';
import useEndpoint from 'app/lib/useEndpoint';
import { SUPPORTED_LOCALES } from 'i18n';
import { useLocale, useTranslations } from 'next-intl';
import { useState } from 'react';
import { useSWRConfig } from 'swr';

const Preferences = () => {
  const fetchWithAuth = useFetchWithAuth();
  const t = useTranslations();
  const { mutate } = useSWRConfig();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const locale = useLocale();
  const {
    data,
    isLoading,
    error: fetchingError,
  } = useEndpoint('/settings/language');

  const handleSave = async (language: string) => {
    try {
      setIsSaving(true);
      await fetchWithAuth('/settings/language', {
        method: 'PUT',
        body: JSON.stringify({ language }),
      });
      await mutate('/settings/language');
      setIsSaving(false);
    } catch (error) {
      let errorMessage = 'An unexpected error occurred. Please try again.';
      console.error(error);

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      setError(errorMessage);
      setIsSaving(false);
    }
  };

  if (fetchingError || error) {
    return (
      <InlineError text={error || JSON.stringify(fetchingError)} type="error" />
    );
  }

  if (isLoading) {
    return <Loader type="inline" />;
  }

  if (!data) {
    return <MissingDataError />;
  }

  return (
    <div className="w-full flex justify-between">
      <div className="flex flex-col">
        <p className="font-bold">{t('Settings.preferences.language')} </p>
      </div>
      <Dropdown.Default
        align="right"
        options={SUPPORTED_LOCALES.map((language) => ({
          label: t(`Settings.preferences.languages.${language}`),
          value: language,
        }))}
        onChange={(e) => void handleSave(e)}
        value={locale}
        disabled={isSaving}
      />
    </div>
  );
};
export default Preferences;
