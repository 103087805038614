import { Button } from 'app/components/Button';
import Icon from 'app/components/Icon';
import classNames from 'classnames';

type DeleteIconButtonProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};
const DeleteIconButton = ({ onClick, className }: DeleteIconButtonProps) => (
  <Button.Default
    aria-label="delete"
    variant="invisible"
    onClick={onClick}
    className={classNames(className, 'flex items-center !p-0')}
  >
    <Icon
      width={24}
      height={24}
      className="text-carbon-500"
      icon={{
        type: 'line',
        source: 'local',
        name: 'trash-can',
      }}
    />
  </Button.Default>
);
export default DeleteIconButton;
