import * as Plot from '@observablehq/plot';

import type { D3NetZeroObject } from '../..';

export const getKnownDataPlot = ({ data }: { data: D3NetZeroObject[] }) => {
  const knownData = data.filter((d) => !d.future);
  const futureData = data.filter((d) => d.future);

  return [
    Plot.lineY(knownData, {
      x: 'year',
      y: 'value',
      stroke: 'type',
      strokeWidth: 2,
    }),
    Plot.dot(knownData, {
      x: 'year',
      y: 'value',
      fill: '#FFFFFF',
      stroke: 'type',
      strokeWidth: 2,
      r: 3,
    }),
    Plot.dot(futureData, {
      x: 'year',
      y: 'value',
      fill: '#FFFFFF',
      stroke: 'type',
      strokeWidth: 2,
      r: 3,
    }),
  ];
};
