import type { MetricsCubeQueryOptions } from '@carbonfact/shared/src/types/platform/metrics-cube';
import { useFetchWithAuth } from 'app/context/SWRContext';
import useEndpoint from 'app/lib/useEndpoint';
import base64url from 'base64url';

export default function useMetricsCubeQuery(
  options: MetricsCubeQueryOptions | null,
) {
  const fetchWithAuth = useFetchWithAuth();

  // Use a hash of the options as the SWR cache key
  return useEndpoint(
    options
      ? `/metrics-cube/query?cacheKey=${base64url.encode(JSON.stringify(options))}`
      : null,
    {
      fetcher: () =>
        fetchWithAuth('/metrics-cube/query', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: options?.timeFrame ? 'byTimeFrame' : 'byTimePeriod',
            ...options,
          }),
        }),
    },
  );
}
