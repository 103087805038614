import ActionList from 'app/components/ActionList/ActionList';
import DialogComponent from 'app/components/Dialog/Dialog';
import useEndpoint from 'app/lib/useEndpoint';
import dayjs from 'dayjs';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import SettingsModal from './SettingsModal';

const SettingsActionList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const t = useTranslations();
  const { data: metadata } = useEndpoint('/metadata', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { user } = useAuth0();

  const initial = user?.name?.[0].toUpperCase() ?? '#';
  const latestSync = metadata?.latestSync
    ? dayjs(metadata.latestSync).format(
        `DD/MM [${t('SettingsActionList.at')}] HH:mm`,
      )
    : 'N/A';

  return (
    <>
      <ActionList
        className="mb-4"
        items={[
          {
            type: 'action',
            label: user?.name ?? '...',
            helpText: user?.email ?? '...',
          },
          { type: 'divider' },
          {
            type: 'action',
            label: t('SettingsActionList.settings'),
            onClick: () => setIsOpen(true),
          },
          {
            type: 'action',
            label: t('SettingsActionList.documentation'),
            href: 'https://docs.carbonfact.com',
          },
          {
            type: 'destructive',
            label: t('SettingsActionList.logout'),
            href: '/logout',
          },
        ]}
      >
        <div className="flex justify-between w-full">
          <div className="flex flex-col items-start">
            <div className="text-sm font-medium">
              {user?.name || t('SettingsActionList.settings')}
            </div>
            <div title={latestSync} className="mt-1 text-xs text-gray-500">
              {t('SettingsActionList.updated')} {latestSync}
            </div>
          </div>

          <div className="flex items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span className="sr-only">
              {t('SettingsActionList.openUserMenu')}{' '}
            </span>
            <div className="flex h-10 w-10 cursor-pointer flex-col content-center justify-center rounded-full bg-black text-center text-white">
              <span>{initial}</span>
            </div>
          </div>
        </div>
      </ActionList>
      <DialogComponent isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <SettingsModal onClose={() => setIsOpen(false)} />
      </DialogComponent>
    </>
  );
};

export default SettingsActionList;
