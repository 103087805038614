import type { FootprintCategory } from '@carbonfact/shared/src/types/platform';

export const DATA_COLOR_SCALE_SCHEME = [
  '#E1AA9A',
  '#648975',
  '#6E4245',
  '#D5A357',
  '#3A406F',
  '#BE5254',
  '#BA8A69',
  '#C37345',
  '#5A6046',
  '#6C8A9E',
];

export function pickDatavizColor(
  val: string,
): (typeof DATA_COLOR_SCALE_SCHEME)[number] {
  // Automatically use LCA stage colors
  if (
    [
      'rawMaterials',
      'RAW_MATERIALS',
      'processing',
      'COMPONENT_PROCESSING',
      'assembling',
      'ASSEMBLY',
      'distribution',
      'DISTRIBUTION',
      'use',
      'USE',
      'endOfLife',
      'END_OF_LIFE',
    ].includes(val)
  ) {
    return getLcaStageColor(val as FootprintCategory | KayaFootprintCategory);
  }

  // Implementation stolen from https://github.com/Medium/oid
  let hash = 0;
  const length = val.length;

  for (let i = 0; i < length; i++) {
    hash = (hash * 31 + val.charCodeAt(i)) & 0x7fffffff;
  }

  return DATA_COLOR_SCALE_SCHEME[hash % DATA_COLOR_SCALE_SCHEME.length];
}

export type KayaFootprintCategory =
  | 'RAW_MATERIALS'
  | 'ASSEMBLY'
  | 'USE'
  | 'END_OF_LIFE'
  | 'DISTRIBUTION'
  | 'COMPONENT_PROCESSING';

export default function getLcaStageColor(
  type: FootprintCategory | KayaFootprintCategory,
) {
  switch (type) {
    // We manually map instead of using the pickDatavizColor() hash function to ensure
    // each LCA stage has a different color.
    case 'rawMaterials':
    case 'RAW_MATERIALS':
      return DATA_COLOR_SCALE_SCHEME[0];
    case 'processing':
    case 'COMPONENT_PROCESSING':
      return DATA_COLOR_SCALE_SCHEME[1];
    case 'assembling':
    case 'ASSEMBLY':
      return DATA_COLOR_SCALE_SCHEME[2];
    case 'distribution':
    case 'DISTRIBUTION':
      return DATA_COLOR_SCALE_SCHEME[3];
    case 'use':
    case 'USE':
      return DATA_COLOR_SCALE_SCHEME[4];
    case 'endOfLife':
    case 'END_OF_LIFE':
      return DATA_COLOR_SCALE_SCHEME[5];
  }
}
