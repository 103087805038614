import type { CountryCode } from '@carbonfact/shared/src/geographical-areas';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { Input } from 'app/components/Form';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import type { MutableRefObject, ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Dropdown } from '../Dropdown';
import type {
  DropdownCountryProps,
  DropdownDefaultProps,
} from '../Dropdown/variants';
import CancelIconButton from './IconButtons/CancelIconButton';
import DeleteIconButton from './IconButtons/DeleteIconButton';
import EditIconButton from './IconButtons/EditIconButton';
import ValidateIconButton from './IconButtons/ValidateIconButton';

type BaseInput = {
  value: string;
  key: string;
  disabled?: boolean;
  prefix?: string;
  suffix?: string;
  className?: string;
  autoFocus?: boolean;
};
type TextInput = {
  type: 'text';
};
type Label = {
  type: 'label';
};
type NumberInput = {
  type: 'number';
};
type CountryInput = {
  type: 'country';
  autocomplete?: boolean;
  height?: string;
  options: { value: CountryCode; label: string }[];
};
type DropdownInput = {
  type: 'dropdown';
  height?: string;
  autocomplete?: boolean;
  options: { value: string; label: string }[];
};

type Divider = {
  type: 'divider';
};

export type InplaceEditInput = BaseInput &
  (TextInput | DropdownInput | NumberInput | CountryInput | Divider | Label);

type DisclosureChildrenProps = {
  values: ConsumerInterfaceValues;
  hovered?: boolean;
  deleteButton?: ReactNode;
  editButton?: ReactNode;
};

export type ConsumerInterfaceValues = Record<string, string>;

type Disabled = {
  disabled: true;
  inputs?: InplaceEditInput[];
};
type Enabled = {
  disabled?: false;
  inputs: InplaceEditInput[];
};
export type InPlaceEditProps = {
  children: ({
    values,
    hovered,
    deleteButton,
    editButton,
  }: DisclosureChildrenProps) => ReactNode;
  onChange?: (values: ConsumerInterfaceValues) => void;
  onConfirm?: (values: ConsumerInterfaceValues) => void;
  onCancel?: () => void;
  onDelete?: () => void;
  height?: string;
} & (Disabled | Enabled);

const InPlaceEdit = ({
  children,
  inputs,
  onChange,
  height = 'h-8',
  onCancel,
  onConfirm,
  onDelete,
  disabled,
}: InPlaceEditProps) => {
  const [currentValues, setCurrentValues] = useState<InplaceEditInput[]>(
    cloneDeep(inputs || []),
  );
  const [initialValues, setInitialValues] = useState<InplaceEditInput[]>(
    cloneDeep(inputs || []),
  );
  const [hovered, setHovered] = useState<boolean>(false);

  useEffect(() => {
    setCurrentValues(cloneDeep(inputs || []));
  }, [inputs]);

  const handleConfirm = (
    closePanelHandler: (
      focusableElement?:
        | HTMLElement
        | MutableRefObject<HTMLElement | null>
        | undefined,
    ) => void,
  ) => {
    closePanelHandler();
    setHovered(false);
    setInitialValues(cloneDeep(currentValues));
    onConfirm?.(getValues(currentValues));
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDelete?.();
  };

  const handleCancel = useCallback(
    (
      closePanelHandler: (
        focusableElement?:
          | HTMLElement
          | MutableRefObject<HTMLElement | null>
          | undefined,
      ) => void,
    ) => {
      setCurrentValues(cloneDeep(initialValues));
      closePanelHandler();
      setHovered(false);
      onCancel?.();
    },
    [initialValues, onCancel],
  );

  const handleInputChange = ({
    key,
    value,
  }: {
    key: string;
    value: string;
  }) => {
    const newValues: InplaceEditInput[] = [...currentValues];
    const valueToUpdate = newValues.find((aValue) => aValue.key === key);
    if (valueToUpdate) valueToUpdate.value = value;

    setCurrentValues([...newValues]);
    onChange?.(getValues(newValues));
  };

  const getValues = (inplaceEditInputs: InplaceEditInput[]) => {
    return Object.fromEntries(
      inplaceEditInputs
        .filter((v) => v.type !== 'divider')
        .map((input) => [input.key, input.value]),
    );
  };

  const componentRef = useRef<HTMLDivElement>(null);
  if (disabled) {
    // Hack to allow Disclosures as children
    return children({
      values: getValues(currentValues),
    });
  }

  return (
    <Disclosure>
      {({ open, close }) => {
        useEffect(() => {
          const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
              handleCancel(close);
            }
          };

          const handleClickOutside = (event: MouseEvent) => {
            if (
              open &&
              componentRef.current &&
              !componentRef.current.contains(event.target as Node)
            ) {
              handleCancel(close);
            }
          };

          document.addEventListener('keydown', handleEscape);
          document.addEventListener('mousedown', handleClickOutside);

          return () => {
            document.removeEventListener('keydown', handleEscape);
            document.removeEventListener('mousedown', handleClickOutside);
          };
        }, [close, open]);
        return (
          <div ref={componentRef} className="w-full">
            {!open && (
              <DisclosureButton
                as={'div'}
                className={'w-full'}
                disabled={disabled}
                onClick={() => setHovered(false)}
                onMouseLeave={() => !disabled && setHovered(false)}
                onMouseEnter={() => !disabled && setHovered(true)}
              >
                {children({
                  values: getValues(currentValues),
                  hovered,
                  deleteButton: <DeleteIconButton onClick={handleDelete} />,
                  editButton: <EditIconButton onClick={() => null} />,
                })}
              </DisclosureButton>
            )}
            <DisclosurePanel className="flex flex-row items-center bg-white p-1 w-full rounded-[8px] shadow-inplace">
              <div className="flex flex-row  items-center gap-2 flex-wrap w-full">
                {currentValues.map((input) => {
                  const {
                    key,
                    prefix,
                    suffix,
                    className,
                    type: inputType,
                    value,
                    disabled,
                    autoFocus,
                  } = input;

                  if (['text', 'number'].includes(inputType)) {
                    return (
                      <div key={key}>
                        <Input
                          autoFocus={autoFocus}
                          label={prefix}
                          suffix={suffix}
                          type={
                            (inputType === 'number'
                              ? 'number'
                              : 'text') as 'text'
                          }
                          className={classNames(className, 'bg-carbon-100')}
                          value={value}
                          onChange={(value: number | string) => {
                            handleInputChange({
                              key,
                              value: value !== null ? value.toString() : '',
                            });
                          }}
                          layout="horizontal"
                        />
                      </div>
                    );
                  }
                  if (inputType === 'country' || inputType === 'dropdown') {
                    const dropdownProps = {
                      height,
                      prefix,
                      className: classNames('w-28', className),
                      disabled,
                      backgroundColor: 'bg-carbon-100',
                      options: input.options,
                      autocomplete: input.autocomplete,
                      value: value || null,
                      onChange: (newValue: string) => {
                        handleInputChange({
                          key,
                          value: newValue,
                        });
                      },
                    };
                    if (inputType === 'country') {
                      const countryProps: DropdownCountryProps = {
                        ...dropdownProps,
                        options:
                          input.options as DropdownCountryProps['options'],
                        value: value as CountryCode,
                      };
                      return <Dropdown.Country key={key} {...countryProps} />;
                    }
                    if (inputType === 'dropdown') {
                      const defaultProps: DropdownDefaultProps = dropdownProps;
                      return <Dropdown.Default key={key} {...defaultProps} />;
                    }
                  } else if (inputType === 'divider') {
                    return <div key={key} className="w-full  bg-carbon-200" />;
                  } else if (inputType === 'label') {
                    return (
                      <span key={key} className="text-sm font-bold">
                        {value}
                      </span>
                    );
                  }
                })}
              </div>

              <div className="flex flex-row ml-auto items-center self-center">
                <CancelIconButton onClick={() => handleCancel(close)} />
                <ValidateIconButton onClick={() => handleConfirm(close)} />
              </div>
            </DisclosurePanel>
          </div>
        );
      }}
    </Disclosure>
  );
};

export default InPlaceEdit;
