import { LockClosedIcon } from '@heroicons/react/20/solid';
import Icon, { type IconType } from 'app/components/Icon';
import classNames from 'classnames';
import type { Route } from 'next';
import Link from 'next/link';

type NavigationItemProps = {
  active: boolean;
  onClick?: () => void;
  href?: Route | URL;
  children?: React.ReactNode;
  icon: IconType;
  locked?: boolean;
};

const NavigationItem = ({
  active,
  href,
  onClick,
  children,
  icon,
  locked = false,
}: NavigationItemProps) => {
  const activeStyle = 'bg-gray-200 font-bold rounded-md';
  const lockedStyle = 'cursor-not-allowed text-gray-400';
  const className = classNames(
    'flex items-center text-sm px-2 py-[11px] font-medium gap-2 w-full',
    {
      [activeStyle]: active,
      [lockedStyle]: locked,
      'text-gray-800': !locked,
    },
  );

  const Content = () => (
    <div className={className}>
      <LeftIcon />
      <div className="flex-grow flex-shrink min-w-0">
        <div className="flex flex-row items-center justify-start flex-nowrap gap-2">
          {children}
        </div>
      </div>
    </div>
  );

  const LeftIcon = () =>
    // If locked, render a LockClosedIcon, otherwise render an Icon component
    locked ? (
      <LockClosedIcon className="flex-shrink-0 h-5 w-5 text-gray-400" />
    ) : (
      <Icon icon={icon} />
    );

  if (href) {
    return (
      <Link href={locked ? '#' : href}>
        <Content />
      </Link>
    );
  }

  if (onClick) {
    return (
      <div onClick={locked ? () => {} : onClick} className="cursor-pointer">
        <Content />
      </div>
    );
  }

  return <Content />;
};

export default NavigationItem;
